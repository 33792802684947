/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'hoc/Layout/layout'
import Nav from 'components/Nav/nav'
import SubPageHeader from 'components/SubPageHeader'
import CookiesPage from 'components/CookiesPage'
import SEO from '../components/SEO'

const Cookies = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader title="Cookies" imageName="PRIVACY" />
      <CookiesPage />
    </Layout>
  )
}

export default Cookies
