import React from 'react'
import { Wrapper } from './styles'

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <h4>Cookies</h4>
      <p>
        Niniejsza Polityka dotyczy plików „cookies” i odnosi się do strony
        internetowej https://transwell.pl/, której właścicielem jest TRANSWELL
        TRANSPORT DEDYKOWANY SP. Z O.O.
      </p>
      <p>Czym są pliki “cookies”?</p>
      <p>
        Pliki “cookies” to małe pliki tekstowe rejestrujące aktywność
        użytkownika online. Ułatwiają poruszanie się po witrynie oraz
        podejmowanie rozmaitych działań, ponieważ rozpoznają urządzenie
        Użytkownika i wyświetlają stronę dopasowaną do jego indywidualnych
        preferencji. Ich wyłączenie może spowodować błędne działanie strony oraz
        brak dostępu do niektórych funkcji.
      </p>
      <p>
        Jakiego rodzaju plików “cookie” używamy i w jaki sposób je
        wykorzystujemy?
      </p>
      <p>
        Stałe pliki cookie
        <br /> Pliki cookie konieczne do przeprowadzenia podstawowych czynności
        w serwisie, takich, jak transakcje i umieszczanie ogłoszeń. Pozostają na
        urządzeniu użytkownika aż do momentu ręcznego usunięcia.
      </p>
      <p>
        Sesyjne pliki cookie
        <br /> Pliki cookie pozwalające zapamiętać wybory dokonane przez
        Użytkownika i używane m.in. przy logowaniu oraz wyświetlaniu ostatnio
        oglądanych ogłoszeń na stronie głównej. Pozostają na urządzeniu
        użytkownika do czasu wylogowania z serwisu lub zamknięcia przeglądarki.
      </p>
      <p>
        Pliki cookie służące do analiz
        <br /> Pliki cookie pozwalające zbierać dane związane ze sposobem
        korzystania z witryny internetowej, w tym treści klikane przez
        użytkownika podczas przeglądania witryny, i umożliwiające ulepszanie
        działania oraz struktury serwisu.
      </p>
      <p>
        Pliki cookie używane do targetowania
        <br /> Pliki cookie zapamiętujące informacje dotyczące korzystania z
        witryny internetowej, umożliwiające proponowanie użytkownikom promocji i
        innych informacji dostosowanych do ich preferencji.
      </p>
      <p>Usuwanie plików „cookies”</p>
      <p>
        Przeglądarki internetowe domyślnie akceptują pliki cookies. W każdej
        chwili można wyłączyć w przeglądarce ich akceptację. Poniżej
        przedstawione są linki do opisanych przez producentów przeglądarek
        sposobów blokowania plików cookies:
      </p>
      <a
        href="https://support.google.com/chrome/answer/95647?hl=pl"
        target="_blank"
        rel="noreferrer"
      >
        w przeglądarce Chrome
      </a>
      <a
        href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectlocale=pl"
        target="_blank"
        rel="noreferrer"
      >
        w przeglądarce Firefox
      </a>
      <a
        href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
        target="_blank"
        rel="noreferrer"
      >
        w przeglądarce Internet Explorer
      </a>
      <a
        href="https://help.opera.com/en/latest/web-preferences/#cookies"
        target="_blank"
        rel="noreferrer"
      >
        w przeglądarce Opera
      </a>
      <a
        href="https://support.apple.com/kb/ph21411?locale=pl_PL"
        target="_blank"
        rel="noreferrer"
      >
        w przeglądarce Safari
      </a>
      <p>
        W razie jakichkolwiek pytań lub problemów prosimy o kontakt z
        administratorem serwisu pod adresem: biuro@transwell.pl
      </p>
    </Wrapper>
  )
}

export default PrivacyPolicy
