import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 100px 70px;
  @media only screen and (max-width: 1023px) {
    padding: 30px 20px;
  }
  h4 {
    font-weight: 900;
    margin-bottom: 30px;
  }
  p {
    line-height: 1.5;
    margin-bottom: 30px;
  }
  a {
    display: block;
    color: ${({ theme }) => theme.colors.mediumGray};
    margin-bottom: 15px;
  }
`
